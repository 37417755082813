import Lifestyle2Template from '@/modules/questionnaire/components/steps/questionnaire/oxidative-stress/lifestyle-2/Lifestyle2Template';

import { BOOLEAN, STRESS_LEVEL } from '@/modules/questionnaire/api/constants';

export default {
  title: 'Steps/OxidativeStress/Lifestyle2/Lifestyle2Template',
  component: Lifestyle2Template
};

const createStory = props => () => ({
  components: { Lifestyle2Template },
  storyProps: props,
  wrapperStyles: {
    display: 'flex',
    justifyContent: 'center'
  },
  template:
    '<div :style="$options.wrapperStyles"><lifestyle2-template v-bind="$options.storyProps" /></div>'
});

export const base = createStory({
  currentStressLevel: STRESS_LEVEL.RELAXED,
  sleepDeprived: BOOLEAN.YES
});

export const tiredOptionSelected = createStory({
  currentStressLevel: STRESS_LEVEL.TIRED,
  sleepDeprived: BOOLEAN.NO
});

export const stressedOptionSelected = createStory({
  currentStressLevel: STRESS_LEVEL.STRESSED,
  sleepDeprived: BOOLEAN.YES
});

export const overlyStressedOptionSelected = createStory({
  currentStressLevel: STRESS_LEVEL.OVERLY_STRESSED,
  sleepDeprived: BOOLEAN.YES
});

export const unselected = createStory({
  currentStressLevel: '',
  sleepDeprived: ''
});
